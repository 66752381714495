import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CenteredLoader from "./reuseComponent/CenteredLoader";

import "./App.css";
import PlanSummaryContainer from "./components/Admin/PlanDetails/PlanSummary";
import ViewRequestedSite from "./components/Admin/SiteManagement/ViewRequestedSite";
import AddRequestedSiteDetails from "./components/Admin/SiteManagement/AddRequestedSiteDetails";

const RequireAuth = React.lazy(() => import("./routeAuth/RequireAuth"));
const ProtectedRoute = React.lazy(() => import("./routeAuth/ProtectedRoute"));
// const Sidebar = React.lazy(() => import("./components/Admin/Sidebar"));
const Sidebar = React.lazy(() =>
  import("./components/Admin/Sidebar/CollapsibleSidebar")
);

const components = {
  Dashboard: React.lazy(() => import("./components/Admin/Dashboard")),
  Login: React.lazy(() => import("./components/Login")),
  BriefReview: React.lazy(() => import("./components/Admin/BriefReview")),
  Signup: React.lazy(() => import("./components/Signup")),
  UserManagement: React.lazy(() => import("./components/Admin/UserManagement")),
  SiteManagement: React.lazy(() => import("./components/Admin/SiteManagement")),
  AddSite: React.lazy(() =>
    import("./components/Admin/SiteManagement/AddSite")
  ),
  ClientManagement: React.lazy(() =>
    import("./components/Admin/ClientManagement")
  ),
  TestingB: React.lazy(() => import("./components/TestingB")),
  BreifDetailByFile: React.lazy(() =>
    import("./components/Admin/BriefReview/BreifDetailByFile")
  ),
  BreifDetailByForm: React.lazy(() =>
    import("./components/Admin/BriefReview/BreifDetailByForm")
  ),
  Plan: React.lazy(() => import("./components/Admin/Plan")),
  LostPlanContainer: React.lazy(() =>
    import("./components/Admin/Plan/LostPlanContainer")
  ),
  PlanReview: React.lazy(() => import("./components/Admin/Plan/PlanReview")),
  POReview: React.lazy(() => import("./components/Admin/Plan/POReview")),
  CampaignManagement: React.lazy(() =>
    import("./components/Admin/CampainManagement")
  ),
  CampaignDetails: React.lazy(() =>
    import("./components/Admin/CampainManagement/CampaignDetailTable")
  ),
  ViewProfile: React.lazy(() =>
    import("./components/Admin/ClientManagement/ViewProfile")
  ),
  ViewUserProfile: React.lazy(() =>
    import("./components/Admin/UserManagement/ViewProfile")
  ),
  CompetitorReport: React.lazy(() => import("./components/CompetitorReport")),
  CompetitorDetailView: React.lazy(() =>
    import("./components/CompetitorReport/DetailView")
  ),
  PlanDemo2: React.lazy(() => import("./components/PlanDemo2")),
  SiteTablePage: React.lazy(() =>
    import("./components/PlanDemo2/SiteTablePage")
  ),
  ReviewAlterPlan: React.lazy(() =>
    import("./components/Admin/Plan/ReviewAlterPlan")
  ),
  LostPlanReview: React.lazy(() =>
    import("./components/Admin/Plan/LostPlanReview")
  ),
  ViewSite: React.lazy(() =>
    import("./components/Admin/CampainManagement/CampaignDetails")
  ),
  ViewRequestedSite: React.lazy(() =>
    import("./components/Admin/SiteManagement/ViewRequestedSite")
  ),
  AddRequestedSiteDetails: React.lazy(() =>
    import("./components/Admin/SiteManagement/AddRequestedSiteDetails")
  ),
  OperationAnalyst: React.lazy(() =>
    import("./components/Admin/OperationAnalyst/index")
  ),
  OperationAnalystCampaignDetails: React.lazy(() =>
    import("./components/Admin/OperationAnalyst/CampaignDetailTable")
  ),
  OperationAnalystViewSite: React.lazy(() =>
    import("./components/Admin/OperationAnalyst/CampaignDetails")
  ),
  SiteDetail: React.lazy(() =>
    import("./components/Admin/SiteManagement/ViewSite")
  ),
  ApprovedPlan: React.lazy(() =>
    import("./components/Admin/Plan/ApprovedPlanReview")
  ),
  ViewPlan: React.lazy(() => import("./components/PlanDemo2/PlanView")),
  ViewLostPlan: React.lazy(() => import("./components/PlanDemo2/ViewLostPlan")),
  ReviewAlterRequired: React.lazy(() =>
    import("./components/Admin/Plan/ReviewAlterRequired")
  ),
  ReviewAlterPending: React.lazy(() =>
    import("./components/Admin/Plan/ReviewAlterPending")
  ),
  ViewAlterPlan: React.lazy(() =>
    import("./components/PlanDemo2/ViewAlterPlan")
  ),
  PreCampaignForm: React.lazy(() =>
    import("./components/Admin/PreCampaignAnalysis/PreCampaignForm")
  ),
  ModificationRequestPlan: React.lazy(() =>
    import("./components/Admin/Plan/ModificationRequestPlan")
  ),
  CreateBrief: React.lazy(() =>
    import("./components/Admin/BriefReview/CreateBrief")
  ),
  CreateBriefByFile: React.lazy(() =>
    import("./components/Admin/BriefReview/CreateBriefByFile")
  ),
  UpdateBrief: React.lazy(() =>
    import("./components/Admin/BriefReview/UpdateBrief")
  ),
  UpdateBriefByFile: React.lazy(() =>
    import("./components/Admin/BriefReview/UpdateBriefByFile")
  ),
  VisualRepresentation: React.lazy(() =>
    import("./components/Admin/VisualRepresentation")
  ),

  InProgressList: React.lazy(() =>
    import("./components/PlanDemo2/InProgressList")
  ),
  BriefDetailsWithPlan: React.lazy(() =>
    import("./components/PlanDemo2/BriefDetailWithPlan")
  ),
  // Plan Details
  PlanSummaryContainer: React.lazy(() =>
    import("./components/Admin/PlanDetails/PlanSummary")
  ),
  PlanDetails: React.lazy(() => import("./components/Admin/PlanDetails")),

  CompetitorCampaignDetails: React.lazy(() =>
    import("./components/CompetitorReport/AddCompetitorSitesDetail")
  ),
  CreateReportsMenu: React.lazy(() =>
    import("./components/CompetitorReport/CreateReportsMenu")
  ),

  ReportCreationPage: React.lazy(() =>
    import("./components/CompetitorReport/ReportCreationPage")
  ),
  Report: React.lazy(() =>
    import("./components/CompetitorReport/ViewReports/Report")
  ),
};

const routes = [
  { path: "/", element: "Login" },
  { path: "/login", element: "Login" },
  // { path: "signup", element: "Signup" },
  { path: "testing", element: "TestingB" },
  {
    path: "attach-plan/:brief_id",
    element: "PlanDemo2",
    roles: ["key_account_manager", "admin"],
  },
 
  {
    path: "attach-plan/:brief_id/view-plan/:plan_id",
    element: "ViewPlan",
    roles: ["key_account_manager", "admin"],
  },
 


 

 
];

const sidebarRoutes = [
  {
    path: "dashboard",
    element: "Dashboard",
    roles: ["planner", "admin", "key_account_manager"],
  },
  {
    path: "brief",
    element: "BriefReview",
    roles: ["admin", "designer", "key_account_manager"],
  },
  {
    path: "campaign-management",
    element: "CampaignManagement",
    roles: ["admin", "operation_analyst", "planner"],
  },
  {
    path: "campaign-details/:brief_id",
    element: "CampaignDetails",
    roles: ["operation_analyst", "admin", "planner"],
  },
  {
    path: "view-brief-details/:brief_id",
    element: "BriefDetailsWithPlan",
    roles: ["key_account_manager", "admin"],
  },

  {
    path: "brief-review-file/:brief_id",
    element: "BreifDetailByFile",
    roles: ["key_account_manager", "designer", "admin"],
  },
  {
    path: "brief-review-form/:brief_id",
    element: "BreifDetailByForm",
    roles: ["key_account_manager", "designer", "admin"],
  },

  {
    path: "view-lost-plan/:brief_id",
    element: "LostPlanReview",
    roles: ["key_account_manager", "admin", "planner"],
  },
  { path: "user-management", element: "UserManagement", roles: ["admin"] },

  {
    path: "site-management",
    element: "SiteManagement",
    roles: ["admin", "planner", "key_account_manager"],
  },
  {
    path: "site-management/add",
    element: "AddSite",
    roles: ["key_account_manager", "admin"],
  },
  {
    path: "site-management/edit",
    element: "AddSite",
    roles: ["key_account_manager", "admin"],
  },
  {
    path: "site-management/view",
    element: "SiteDetail",
    roles: ["key_account_manager", "admin", "planner"],
  },
  // ViewRequestedSite
  {
    path: "site-management/requested-site/view",
    element: "ViewRequestedSite",
    roles: ["key_account_manager", "admin", "planner"],
  },
  {
    path: "site-management/requested-site/view/add-details",
    element: "AddRequestedSiteDetails",
    roles: ["key_account_manager", "admin", "planner"],
  },
  {
    path: "client-management",
    element: "ClientManagement",
    roles: ["admin", "manager"],
  },
  {
    path: "view-client-profile",
    element: "ViewProfile",
    roles: ["admin", "manager"],
  },
  {
    path: "view-user-profile",
    element: "ViewUserProfile",
    roles: ["admin", "manager"],
  },
  {
    path: "plan",
    element: "Plan",
    roles: ["admin", "planner", "key_account_manager"],
  },
  {
    path: "view-plan/:plan_id",
    element: "ViewPlan",
    roles: ["key_account_manager", "admin", "planner"],
  },
  {
    path: "lostPlan",
    element: "LostPlanContainer",
    roles: ["admin", "planner", "key_account_manager"],
  },
  {
    path: "/inprogress-plans/:brief_id/view-plan/:plan_id",
    element: "ViewPlan",
    roles: ["key_account_manager", "admin", "planner"],
  },
  {
    path: "/inprogress-plans/:brief_id",
    element: "InProgressList",
    roles: ["key_account_manager", "admin", "planner"],
  },
  
  {
    path: "pending-plan-review/:brief_id/view-plan/:plan_id",
    element: "ViewPlan",
    roles: ["key_account_manager", "admin", "planner"],
  },
  {
    path: "/approved-plan-review/:brief_id/view-plan/:plan_id",
    element: "ViewPlan",
    roles: ["key_account_manager", "admin", "planner"],
  },
   
  {
    path: "/view-lost-plan-detail/:plan_id",
    element: "ViewLostPlan",
    roles: ["key_account_manager", "admin", "planner"],
  },

  {
    path: "competitor-report",
    element: "CompetitorReport",
    roles: ["admin", "planner", "key_account_manager"],
  },
  {
    path: "competitor-report/view-details/:competitor_id",
    element: "CompetitorDetailView",
    roles: ["admin", "planner", "key_account_manager"],
  },
  {
    path: "competitor-report/:competitor_id/campaign-details/:campaign_id",
    element: "CompetitorCampaignDetails",
    roles: ["admin", "planner"],
  },
  {
    path: "competitor-report/:competitor_id/campaign-details/:campaign_id/reports",
    element: "CreateReportsMenu",
    roles: ["admin", "planner"],
  },
  {
    path: "competitor-report/:competitor_id/campaign-details/:campaign_id/reports/create/:report_type",
    element: "ReportCreationPage",
    roles: ["admin", "planner"],
  },
  {
    path: "competitor-report/:competitor_id/campaign-details/:campaign_id/reports/create/:report_type/view",
    element: "Report",
    roles: ["admin", "planner"],
  },
  {
    path: "pending-plan-review/:plan_id",
    element: "PlanReview",
    roles: ["admin", "planner", "key_account_manager"],
  },
  
  {
    path: "view-modification-request/:brief_id/view-plan/:plan_id",
    element: "ModificationRequestPlan",
    roles: ["planner", "key_account_manager", "admin"],
  },
  {
    path: "modification-plan/:brief_id",
    element: "ReviewAlterPlan",
    roles: ["planner", "key_account_manager", "admin"],
  },
  {
    path: "modification-required/:brief_id",
    element: "ReviewAlterRequired",
    roles: ["planner", "key_account_manager", "admin"],
  },
  {
    path: "modification-pending/:brief_id",
    element: "ReviewAlterPending",
    roles: ["planner", "editor", "admin"],
  },
  {
    path: "modification-required/:brief_id/view-plan/:plan_id",
    element: "ViewPlan",
    roles: ["key_account_manager", "admin", "planner"],
  },
  {
    path: "modification-pending/:brief_id/view-plan/:plan_id",
    element: "ViewPlan",
    roles: ["key_account_manager", "admin", "planner"],
  },
  {
    path: "plan-po-review",
    element: "POReview",
    roles: ["admin", "key_account_manager"],
  },
  {
    path: "view-sites/:site_id",
    element: "ViewSite",
    roles: ["admin", "key_account_manager", "operation_analyst", "planner"],
  },
  {
    path: "operation-analyst",
    element: "OperationAnalyst",
    roles: ["admin", "operation_analyst", "key_account_manager"],
  },
  {
    path: "operation-analyst/:brief_id",
    element: "OperationAnalystCampaignDetails",
    roles: ["admin", "operation_analyst", "key_account_manager"],
  },
  {
    path: "view-site/:site_id",
    element: "OperationAnalystViewSite",
    roles: ["admin", "planner", "key_account_manager"],
  },
  {
    path: "approved-plan-review/:brief_id",
    element: "ApprovedPlan",
    roles: ["planner", "key_account_manager", "admin"],
  },
  {
    path: "add-plan-sites/:plan_id",
    element: "SiteTablePage",
    roles: ["planner", "key_account_manager", "admin"],
  },
  {
    path: "modification-required/:brief_id/view-plan/:plan_id",
    element: "ViewPlan",
    roles: ["planner", "key_account_manager", "admin"],
  },

  // {
  //   path: "modification-pending/:brief_id/view-plan/:plan_id",
  //   element: "ViewPlan",
  //   roles: ["planner", "key_account_manager", "admin"],
  // },
  {
    path: "view-modification-request/:brief_id/view-plan/:plan_id",
    element: "ModificationRequestPlan",
    roles: ["planner", "key_account_manager", "admin"],
  },
  {
    path: "/pre-campaign-form/:plan_id",
    element: "PreCampaignForm",
    roles: ["planner", "key_account_manager", "admin"],
  },
  {
    path: "/create-brief",
    element: "CreateBrief",
    roles: ["admin", "key_account_manager"],
  },
  {
    path: "/create-brief-by-file",
    element: "CreateBriefByFile",
    roles: ["admin", "key_account_manager"],
  },
  {
    path: "/update-brief/:brief_id",
    element: "UpdateBrief",
    roles: ["admin", "key_account_manager"],
  },
  {
    path: "/update-brief-by-file/:brief_id",
    element: "UpdateBriefByFile",
    roles: ["admin", "key_account_manager"],
  },
  {
    path: "/plan/visual-representation",
    element: "VisualRepresentation",
    roles: ["planner", "admin", "editor"],
  },
  {
    path: "/plan/plan-summary/:plan_id",
    element: "PlanSummaryContainer",
    roles: ["planner", "admin", "key_account_manager"],
  },
  {
    path: "/plan/plan-summary-details/:plan_id",
    element: "PlanDetails",
    roles: ["planner", "admin", "key_account_manager"],
  },
];

const App = () => {
  const [resourcesLoaded, setResourcesLoaded] = useState(false);

  useEffect(() => {
    const checkResourcesLoaded = () => {
      const allStyles = Array.from(document.styleSheets).every(
        (styleSheet) => styleSheet.cssRules || styleSheet.rules
      );
      if (allStyles) setResourcesLoaded(true);
    };
    if (document.readyState === "complete") checkResourcesLoaded();
    else window.addEventListener("load", checkResourcesLoaded);
    return () => window.removeEventListener("load", checkResourcesLoaded);
  }, []);

  if (!resourcesLoaded) return <CenteredLoader />;

  return (
    <Routes>
      {routes.map(({ path, element, roles }) => (
        <Route
          key={path}
          path={path}
          element={
            <Suspense fallback={<CenteredLoader />}>
              {roles ? (
                <RequireAuth>
                  <ProtectedRoute allowedRoles={roles}>
                    {React.createElement(components[element])}
                  </ProtectedRoute>
                </RequireAuth>
              ) : (
                React.createElement(components[element])
              )}
            </Suspense>
          }
        />
      ))}
      <Route
        path="/"
        element={
          <Suspense fallback={<CenteredLoader />}>
            <RequireAuth>
              <ProtectedRoute
                allowedRoles={[
                  "planner",
                  "designer",
                  "editor",
                  "admin",
                  "key_account_manager",
                  "manager",
                ]}
              >
                <Sidebar />
              </ProtectedRoute>
            </RequireAuth>
          </Suspense>
        }
      >
        {sidebarRoutes.map(({ path, element, roles }) => (
          <Route
            key={path}
            path={path}
            element={
              <Suspense fallback={<CenteredLoader />}>
                <RequireAuth>
                  <ProtectedRoute allowedRoles={roles}>
                    {React.createElement(components[element])}
                  </ProtectedRoute>
                </RequireAuth>
              </Suspense>
            }
          />
        ))}
      </Route>
    </Routes>
  );
};

export default App;
